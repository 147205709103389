import { motion, useAnimation } from "framer-motion";
import React, { useEffect, useState } from "react";

import "./style.css";
import Helmet from "react-helmet";
import Layout from "../../components/layout";

export default function Project({ data }) {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [lightboxImage, setLightboxImage] = useState(null);
  const controls = useAnimation();

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxImage(data.img[index].url);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  const prevSlide = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setLightboxImage(data.img[currentImageIndex - 1].url);
    }
  };

  const nextSlide = () => {
    if (currentImageIndex < data.img.length - 1) {
      openLightbox(currentImageIndex + 1);
    }
  };

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.2 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>John Mamanao - {data.title}</title>
      </Helmet>
      {lightboxImage && (
        <div className="modal" onClick={closeLightbox}>
          <img
            src={lightboxImage}
            alt=""
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
            loading="lazy"
          />
          <span className="close-button" onClick={closeLightbox}>
            &times;
          </span>
          {currentImageIndex > 0 && (
            <button className="prev-button cursor-pointer" onClick={(e) => {
              e.stopPropagation();
              prevSlide();
            }}>
          Prev
        </button>
          )}
          {currentImageIndex < data.img.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                nextSlide();
              }}
              className="next-button"
            >
              Next
            </button>
          )}
        </div>
      )}
      <div className="max-w-screen-xl m-auto min-h-full h-full lg:flex lg:gap-5">
        <motion.div
          layoutId={data.slug}
          className="max-w-[1400px] max-h-[400px] h-full w-full m-auto py-8 relative group flex items-center justify-center lg:h-screen md:h-auto"
        >
          <img
            className="max-w-full max-h-full w-auto h-auto lg:w-full rounded-2xl duration-500 object-cover border cursor-pointer p-5"
            src={data.img[currentImageIndex].url}
            alt=""
            onClick={() => setLightboxImage(data.img[currentImageIndex].url)}
            loading="lazy"
          />
        </motion.div>
        <div className="w-full flex flex-col justify-center">
          <motion.div
            custom={1}
            animate={controls}
            className="text-4xl font-extrabold sm:text-3xl md:text-4xl lg:text-4xl xl:text-4xl"
            style={{ opacity: 0, x: -10 }}
          >
            {data.title}
          </motion.div>
          <br />
          <motion.div
            custom={1}
            animate={controls}
            style={{ opacity: 0, x: -10 }}
            className="text-lg sm:text-xl md:text-lg lg:text-xl xl:text-2xl font-thin"
          >
            {data.description}
          </motion.div>
          <br />
          <motion.div
            custom={2}
            animate={controls}
            style={{ opacity: 0, x: -10 }}
            className="text-lg sm:text-xl md:text-lg lg:text-xl xl:text-2xl font-thin"
          >
            <b>Technology</b> : {data.technology.map((e) => ` ${e},`)}
          </motion.div>
          <motion.div
            custom={3}
            animate={controls}
            style={{ opacity: 0, y: -10 }}
            className="flex gap-5 mt-10 mb-5"
          >
            {data.link.map((e, i) => (
              <a
                key={i}
                href={e.url}
                target="_blank"
                className="p-2 border-solid border-2 border-white-500 hover:border-[#243c5a] hover:bg-white hover:text-black cursor-pointer text-sm sm:text-md md:text-lg lg:text-xl xl:text-2xl"
              >
                {e.text}
              </a>
            ))}
          </motion.div>
        </div>
      </div>
    </Layout>
  );
}
