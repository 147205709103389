import { AnimatePresence, motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import { useEffect, useState } from "react";

export default function Competency({ data }) {
  const [lightboxImage, setLightboxImage] = useState(null);
  const controls = useAnimation();

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.2 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>John Mamanao - Competency Skills</title>
      </Helmet>
      {lightboxImage && (
        <div className="modal" onClick={closeLightbox}>
          <img
            src={lightboxImage}
            alt=""
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
            loading="lazy"
          />
          <span className="close-button" onClick={closeLightbox}>
            &times;
          </span>
        </div>
      )}
      <motion.div
        animate={controls}
        custom={4}
        style={{ opacity: 0 }}
        className={
          "max-w-screen-xl m-auto min-h-full w-full h-full z-10"
        }
      >
        <section className="w-full h-full flex flex-col items-center">
          <div className="mt-20" />
          <div className="max-w-6xl">
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-5xl font-bold mb-20">My Competency Skills</h1>
            </div>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-2xl mb-5 font-bold italic">Systems Development activities for systems development competency</h1>
            </div>
            <div className="grid">
              <AnimatePresence>
                <motion.div
                  className="border-solid border-2 border-white flex justify-center p-2 h-26"
                  custom={3}
                  animate={controls}
                  style={{ opacity: 0, y: -10 }}
                >
                  <img className="w-full object-contain cursor-pointer" src={data.image} alt="" onClick={() => {setLightboxImage(data.image);}} loading="lazy"/>
                </motion.div>
              </AnimatePresence>
            </div>
            <div className="mb-20 mt-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  NOTE:
                </h1>
              </div>
              <p className="text-xm mb-10 font-thin italic">
                {data.note}
              </p>
            </div>
            <div className="mb-20 mt-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  About
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.about}
              </p>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  );
}
