import { motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import { useEffect, useState } from "react";
// import "../ojt-experience/style.css";
import { Media } from "../../../media";
import "./style.css";

export default function Experience({ data }) {
  const [lightboxImage, setLightboxImage] = useState(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const controls = useAnimation();

  const openLightbox = (index) => {
    setCurrentImageIndex(index);
    setLightboxImage(Media[index].url);
  };

  const closeLightbox = () => {
    setLightboxImage(null);
  };

  const goToPreviousImage = () => {
    if (currentImageIndex > 0) {
      setCurrentImageIndex(currentImageIndex - 1);
      setLightboxImage(Media[currentImageIndex - 1].url);
    }
  };

  const goToNextImage = () => {
    if (currentImageIndex < Media.length - 1) {
      openLightbox(currentImageIndex + 1);
    }
  };

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.4 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.helmet.title}</title>
      </Helmet>
      {/* SHOW IMAGE MODAL */}
      {lightboxImage && (
        <div className="modal" onClick={closeLightbox}>
          <img
            src={lightboxImage}
            alt=""
            className="modal-image"
            onClick={(e) => e.stopPropagation()}
            loading="lazy"
          />
          <span className="close-button" onClick={closeLightbox}>
            &times;
          </span>
          {currentImageIndex > 0 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                goToPreviousImage();
              }}
              className="prev-button"
            >
              Prev
            </button>
          )}
          {currentImageIndex < Media.length - 1 && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                goToNextImage();
              }}
              className="next-button"
            >
              Next
            </button>
          )}
        </div>
      )}
      <motion.div
        animate={controls}
        custom={4}
        // layoutId={"about"}
        style={{ opacity: 0 }}
        className={"max-w-screen-xl m-auto min-h-full w-full h-full z-10"}
      >
        <section className="w-full h-full flex flex-col items-center">
          <div className="mt-20" />
          <div className="max-w-6xl">
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-5xl font-bold mb-20">{data.title}</h1>
            </div>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl mb-5 font-bold italic">{data.intro}</h1>
            </div>
            {data.essay.map((e) => (
              <p
                className="text-xl mb-10 font-thin"
                dangerouslySetInnerHTML={{ __html: e }}
              />
            ))}
            <div className="mb-20 mt-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.mission_title}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.mission_description}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.vision_title}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.vision_description}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.company}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.company_description}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">ADDRESS</h1>
              </div>
              <p className="text-xl mb-10 font-thin">{data.address}</p>
            </div>

            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl mb-5 font-bold italic">EVIDENCES:</h1>
            </div>
            <div className="flex flex-col">
              <div className="md:flex flex-wrap justify-between md:pb-1 grid grid-cols-1 md:grid-cols-2 gap-5">
                {Media.map((file, i) => (
                  <img
                    key={file.id || i}
                    className="w-full h-full border md:w-1/2 lg:w-full prev"
                    src={file.url}
                    alt=""
                    onClick={() => {
                      setLightboxImage(file.url);
                    }}
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  );
}
