const portfolio = {
  // Resume : Contain Information Regarding Resume Page, all the static reside here, modification here will reflect on the website
  resume: {
    projects: {
      title: "PROJECTS",
      list: [
        {
          title: "DaVista: A Nano Racing Game Inspired by Davao City Tourist Spots",
          description:
            "DaVista is an exciting nano racing game that takes players on a thrilling journey through the vibrant city of Davao. Inspired by the real-life tourist spots of this beautiful destination, the game offers an immersive racing experience like no other. Race through meticulously designed tracks that mirror the iconic landmarks of Davao City, from the majestic Mount Apo to the stunning Pearl Farm Beach Resort. With stunning graphics and intense gameplay, DaVista captures the essence of Davao's culture, history, and natural beauty. Get ready to rev your engines and explore this virtual world, filled with excitement, speed, and unforgettable sights.",
        },
        {
          title: "Developing a Web-Based Sales and Inventory Management System for Brightside Party Needs",
          description:
            "The project aims to design and develop a web-based sales and inventory management system for Brightside Party Needs. The system's main objective is to provide efficient tracking of products, enabling real-time visibility of stock availability. It will allow seamless updating of the store's inventory, ensuring accurate records. Additionally, the system will capture and generate comprehensive sales data, facilitating informed decision-making. With this solution in place, Brightside Party Needs can optimize their operations, improve customer service by preventing stockouts, and gain valuable insights into their sales performance. The web-based nature of the system offers accessibility and convenience, enabling efficient management of sales and inventory anytime, anywhere.",
        },
        {
          title: "Socio-Economic Database with Geographic Information System",
          description:
            "A Socio-Economic Database integrated with a Geographic Information System (GIS). This powerful tool combines social and economic data with geographic mapping, allowing for comprehensive analysis and visualization. With this database, users can explore relationships between socio-economic factors and geographic locations, enabling informed decision-making for various sectors.",
        }
      ],
    },
    info: {
      title: "INFO",
      name: "John  Charles Frederick S. Mamanao",
      age: ", 23, ♂",
      list: [
        ["Email - ", "beastnectus@gmail.com"],
        ["Github - ", "BeastNectus"],
        ["LinkedIn - ", "John Mamanao"],
        ["Facebook - ", "John Charles Frederick Mamanao"],
      ],
    },
    education: {
      title: "EDUCATION",
      list: [
        {
          company: "Jose Maria College Foundation Inc.",
          course: "Bachelor of Science - BS, Information Technology",
          range: "S.Y 2019 - 2023 : ",
        },
      ],
    },
    experience: {
      title: "EXPERIENCE",
      list: [
        {
          range: "2023 - Present ",
          company: "EBOS CLOUD ACCOUNTANT",
          title: " Backend Developer",
          description: ` - Responsibilities include integrating third-party APIs, strong backend web development skills (HTML, CSS, PHP, SQL, JavaScript), familiarity with Laravel framework, creation and integration of WebSockets, applying CSS styling, ensuring cross-browser/platform compatibility, basic WordPress with Divi, understanding of converting web to mobile apps, creating/integrating webhooks, willingness to experiment in new disciplines, and comfort with client-facing video calls.`,
        },
        {
          range: "2022 - 2023 ",
          company: "NATIONAL ECONOMIC DEVELOPMENT AUTHORITY REGION XI",
          title: " System Development - Socio-Economic Database with Geographic Information System",
          description: ` - As part of development we developed a Socio-Economic Database integrated with a Geographic Information System (GIS). This powerful tool combines social and economic data with geographic mapping, allowing for comprehensive analysis and visualization. With this database, users can explore relationships between socio-economic factors and geographic locations, enabling informed decision-making for various sectors.`,
        },
      ],
    },
    bio: {
      title: "BIO",
      description: `
      Hey, I'm John Charles Frederick Mamanao, A passionate programmer, Currently living in
          Philippines. If you are looking for a developer, I am currently
          exploring job opportunities, feel free to contact me.
          <br/><br/>I love programming, and I have been doing it since i was 19 years old,
          I am passionate about programing and in the past 4 years, i have worked with alot of
          projects, frameworks, and languages. In my spare time I usually
          coding and explore new frameworks.
      `,
    },
    skills: {
      title: "SKILLS & TRAITS",
      list: [
        "Hard-Working",
        "Teamwork",
        "Problem-Solving",
        "Self Motivated",
        "Passionated",
        "Kind",
        "Honest",
        "Loyalty",
        "Innovation",
        "Willingness",
      ],
    },
    tech_stacks: {
      title: "TECHSTACKS",
      list: [
        "React Native",
        "React JS",
        "JavaScript",
        "HTML & CSS",
        "MySQL",
        "PHP",
        "Laravel",
        "Unreal Engine Blueprint Scripting",
      ],
    },
    tools: {
      title: "TOOLS",
      list: [
        "WordPress",
        "CPanel",
        "Divi",
        "Elementor",
        "Figma",
        "Git",
        "Postman",
        "Blender",
        "Adobe Photoshop & Illustrator",
        "Microsoft Excel, Word, Powerpoint",
      ],
    },
    familiar: {
      title: "FAMILIAR",
      list: [
        "Python",
        "C#",
        "C++",
        "PostgreSQL",
        "Lua Script",
        "TypeScript",
      ]
    },
    title: "Web Developer",
  },
  // Layout : Contain Information Regarding Layout of the Pages, all the static reside here, modification here will reflect on the website
  layout: {
    right: [
      {
        url: "/resume",
        label: "Resume",
        link: true,
      },
      {
        url: "/contact-me",
        label: "Email",
        link: true
      },
      {
        url: "https://github.com/BeastNectus",
        label: "GitHub",
      },
      {
        url: "https://www.linkedin.com/in/john-mamanao-762218278",
        label: "LinkedIn",
      },
    ],
    left: [
      {
        url: "/",
        label: "Home",
      },
      {
        url: "/projects",
        label: "Projects",
      },
      {
        url: "/designs",
        label: "Designs",
      },
      {
        url: "/extra-information",
        label: "Extra",
      },
      {
        url: "/about-me",
        label: "About",
      },
      {
        url: "/contact-me",
        label: "Contact",
      }
    ],
  },
  // Contact : Contain Information Regarding Contact Page, all the static reside here, modification here will reflect on the website
  contact: {
    helmet: {
      title: "John Mamanao - Contact Me",
    },
    title: "GET IN TOUCH",
    description:
      "I am currently living in philippines and exploring opportunities around me and my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!",
    btn: {
      label: "Say Hi 👋",
      url: "",
    },
    ending: "Or beastnectus@gmail.com",
  },
  // About : Contain Information Regarding About Page, all the static reside here, modification here will reflect on the website
  About: {
    helmet: {
      title: "John Mamanao - About Me",
    },
    title: "BIO",
    social: {
      label: "🏃 Follow me on ",
      links: [
        { label: "Twitter", url: "https://twitter.com/beastnectus12" },
        { label: "Linkedin", url: "https://www.linkedin.com/in/john-mamanao-762218278" },
        { label: "GitHub", url: "https://github.com/BeastNectus" },
        { label: "Facebook", url: "https://www.facebook.com/Venectus" },
      ],
    },
    description: [
      ` I am John Charles Frederick S. Mamanao, a dedicated and versatile programmer with a strong passion for continuous learning and adaptability to evolving environments. With four years of experience in the programming field, I have acquired a diverse skill set and a solid foundation in various programming languages, frameworks, and tools.`,
      ` Known for my ability to quickly grasp new concepts and adapt to changing circumstances, I thrive on challenges that push the boundaries of my knowledge and skills. I possess a natural curiosity and a hunger for learning, which enables me to stay updated with the latest industry trends and technologies.`,
      `I specialize in PHP, HTML&CSS, ReactJS, React Native, PostgreSQL, C#, Python, C++, and JavaScript, showcasing my proficiency in both front-end and back-end development. I have a deep understanding of web development technologies and frameworks, and I have successfully leveraged my expertise to create responsive and engaging user interfaces.`,
      `   In addition to my programming skills, I have also gained valuable experience as a game developer, working with Unreal Engine and Blender. My four-month experience in the game development field has honed my creativity and problem-solving abilities, enabling me to create immersive and visually stunning gaming experiences.`,
      `   With a strong work ethic and an eagerness to tackle new challenges, I approach every project with dedication and attention to detail. I excel in collaborative environments, where I can contribute my knowledge and expertise while learning from others. My effective communication skills and team-oriented mindset make me a valuable asset in any development team.`,
      `   Outside of programming, I enjoy exploring new technologies, participating in online communities, and attending industry events to expand my knowledge and network. My commitment to personal growth and professional development ensures that I am always at the forefront of emerging trends and advancements in the programming world.`,
      `   As a fast learner, adaptable professional, and passionate programmer, I am poised to take on new challenges and make a significant impact in the world of programming and game development. My commitment to excellence and my ability to adapt to changing circumstances make me a valuable asset in the ever-evolving tech industry.`,
      `  I'm usually focused on : <br /> 
              🎛️ Designing great User interfaces experience <br />
              🌐 Developing full-stack websites that scales <br />
              🎮 Game Development, <br />
              ⏱️ Efficiency <br />
              🎯 Consistency <br />
              ♻️ Reusability <br />
              ⚙️ Systems <br />
              📋 Standards <br />`,
    ],
  },
  // Home : Contain Information Regarding Home Page, all the static reside here, modification here will reflect on the website
  home: {
    showModel: true,
    helmet: {
      title: "JM - Welcome to my Dev-Folio",
    },
    btn: {
      label: "More",
      hire: "Hire me",
      projects: "Projects",
      designs: "Designs"
    },
    title: "a Web Developer",
    q1: "Who am I and who do I want to become?",
    q2: "Which skills do I possess?",
    q3: "Whom do I want to surround myself with?",
    q4: "What goals do I want to reach?",
    q5: "Which companies do I want to work for?",
    q6: "What type of job do I want to do?",
    q1a: "I have a diverse range of skills in technology and digital media and my passion for game development, web development, and 3D art demonstrates my creativity and technical ability, and to reach my goals, it's crucial to continue learning and taking on challenges, network with others in my field, and pursue my aspirations with determination.",
    q2a: "I have a variety of technical and digital skills and my enthusiasm for game design, web development, and 3D artwork showcases my imagination and technical proficiency.",
    q3a: "I want to surround myself with people who are passionate and knowledgeable in their field and can share their knowledge and inspire me to continue to improve. Building a network of supportive, like-minded individuals can help me achieve my goals and provide me with support and encouragement along the way.",
    q4a: "My goals is to enhance my skills in web development and game development and explore other related areas.",
    q5a: "Seeking employment at tech firms prioritizing innovation, creativity, and social responsibility, inspired by companies like Google, Apple, and Amazon.",
    q6a: "Aspiring to work as a web developer, specializing in creating visually appealing and user-friendly websites utilizing the latest web development trends and technologies.",
    subTitle: "Hi, I am John Mamanao Web Developer",
    description: `I describe my self as ~ Tech Saavy ~ Highly qualified ~ Robust academic background ~ Enthusiasm for technology ~ Programming ~ Database ~ Critical thinking ~ Strong aptitude for learning ~ Confident ~ Valuable contributions ~ Organization`,
    
  },
  Helmet: {
    projects: " John Mamanao - Projects",
    designs: " John Mamanao - Designs",
    extras: "John Mamanao - Extras"
  },
  // Projects : Contain Information Regarding Projects Page, all the static reside here, modification here will reflect on the website
  projects: [
    {
      technology: ["Unreal Engine 5", "Blender", "Photoshop"],
      slug: "projects/Davista",
      title: "DaVista",
      description:
        "The game's objective is to create an engaging experience that promotes tourism in Davao City. It aims to familiarize tourists with the city's popular landmarks, providing historical information and trivia along the way. The game employs gamification techniques, utilizing the latest trends, to enhance promotional efforts. By offering multiple modes, players can enjoy different gameplay experiences and choose their preferred style of exploration. Each mode presents unique objectives, challenges, and mechanics, ensuring an exciting and enticing gameplay that encourages players to return for more.",
      image: "https://iili.io/dVqmxFp.png",
      img: [
        {
          url: "https://iili.io/dVqmxFp.png",
        },
      ],
      link: [
        {
          text: "Game Trailer",
          url: "https://www.youtube.com/watch?v=m6YKyCJjtlA",
        }
      ],
    },
    {
      technology: ["PHP", "MySQL", "JavaScript", "HTML & CSS", "Photoshop"],
      slug: "projects/Developing-a-Web-Based-Sales-and-Inventory-Management-System-for-Brightside-Party-Needs",
      title: "BrightSide",
      description:
        "The suggested system is advised to the business owner to adapt to the global technological shift, providing accessibility through separate administrator and cashier accounts, with restricted editing capabilities for administrators to prevent unintentional removal, and featuring qualities like a sleek and simple user interface, user-friendliness, and on-site transaction functionality.",
      image: "https://iili.io/dVqyca2.png",
      img: [
        {
          url: "https://iili.io/dVqyca2.png",
        },
      ],
      link: [
        {
          text: "View Documentation",
          url: "https://drive.google.com/file/d/1LNvTO-d5Z8xiEnsOydTFWnnr3a59MBA_/view?usp=sharing",
        },
      ],
    },
    {
        technology: [ "Laravel", "MySQL", "PHP", "Bootstrap", "Tailwind" ],
        slug: "projects/axiospos",
        title: "Axios Inventory System with POS",
        description:
          "Axios POS combines powerful sales capabilities with robust inventory management to streamline business operations. Experience seamless transactions, precise inventory tracking, and enhanced customer relationship management, all integrated into one solution with Axios POS.",
        image: "https://iili.io/dVB91lR.png",
        img: [
          {
            url: "https://iili.io/dVB91lR.png",
          },
        ],
        link: [
          {
            text: "Github Repository",
            url: "https://github.com/BeastNectus/axios-pos",
          },
        ],
    },
    {
      technology: [ "WordPress", "Elementor" ],
      slug: "projects/chipcoins",
      title: "ChipCoins",
      description:
        "Explore ChipCoins, the leading cryptocurrency platform reshaping the landscape of digital finance. Empowering users with secure investment opportunities, seamless trading capabilities, and efficient transactions, ChipCoins stands at the forefront of blockchain innovation. Our intuitive interface seamlessly integrates with popular wallets and exchanges, ensuring a user-friendly experience. With a dedicated team committed to innovation and security, ChipCoins offers unparalleled stability and reliability in the dynamic cryptocurrency market. Join us today and embark on a journey to unlock the boundless potential of digital finance with ChipCoins.",
      image: "https://iili.io/dVBHehF.png",
      img: [
        {
          url: "https://iili.io/dVBHehF.png",
        },
      ],
      link: [
        {
          text: "Figma Design",
          url: "https://www.figma.com/file/QS7ZfMUcVG2ORSFK3SF89y/ChipCoins---Cryptocurrency-Landing-Page?type=design&node-id=1%3A2&mode=design&t=F4keU0LhPbwnZ2hW-1"
        },
        {
          text: "Live Site",
          url: "https://chipcoins-wordpress.vercel.app/",
        },
      ],
    },
    {
      technology: [ "WordPress", "Elementor" ],
      slug: "projects/medicplus",
      title: "MedicPlus",
      description:
        "Explore MedicPlus, your comprehensive healthcare solution designed to meet all your medical needs. Easily connect with experienced medical professionals through our platform, providing convenient access to quality care from anywhere. Whether you require a virtual consultation, prescription refills, or specialized treatment, MedicPlus delivers personalized healthcare solutions tailored to you. With a network of certified practitioners and cutting-edge telemedicine technology, we ensure prompt and reliable medical assistance. Experience a new era of accessible and patient-centric healthcare – join MedicPlus today.",
      image: "https://iili.io/dVBJrMu.png",
      img: [
        {
          url: "https://iili.io/dVBJrMu.png",
        },
      ],
      link: [
        {
          text: "Figma Design",
          url: "https://www.figma.com/file/O4GZNP4eYe441prqpcPSJY/Medic-Landing-Page?type=design&node-id=45%3A473&mode=design&t=27O1U1Hhpg5UuxbH-1"
        },
        {
          text: "Live Site",
          url: "https://medic-plus.vercel.app/",
        },
      ],
    },
    {
        technology: [ "AngularJs", "TypeScript", "Tailwind" ],
        slug: "projects/rainftb",
        title: "RainFTB",
        description:
          "At RainFTB, experience the forefront of crypto bidding, designed for enthusiasts seeking excitement and opportunity. Dive into a world of digital currencies with our innovative platform, offering a dynamic environment for seasoned traders and newcomers alike. Engage in thrilling bidding wars on a diverse range of coins, all within a seamless and secure interface. Our transparent processes ensure confidence as you explore new investment avenues. Join RainFTB today and let the cryptocurrency rain pour down, unlocking the full potential of the digital economy.",
        image: "https://iili.io/dVBdwWF.png",
        img: [
          {
            url: "https://iili.io/dVBdwWF.png",
          },
        ],
        link: [
          {
            text: "Figma Design",
            url: "https://www.figma.com/file/mxcC4yqi4zmcmQD63kN7nk/RainFTB---Landing-Page?type=design&mode=design&t=UmAdhhQlIH5BQINn-1"
          },
          {
            text: "Live Site",
            url: "https://rainftb.vercel.app/",
          },
        ],
    },
    {
        technology: [ "AngularJs", "TypeScript", "Tailwind", "API" ],
        slug: "projects/movid",
        title: "MoVid",
        description:
          "This project was developed using AngularJS seamlessly integrated with the VidSrc API, showcasing the harmonious fusion of modern front-end technology with robust video streaming capabilities.",
        image: "https://iili.io/dVB20Xt.png",
        img: [
          {
            url: "https://iili.io/dVB3caj.png",
          },
        ],
        link: [
          {
            text: "Live Site",
            url: "https://movid.vercel.app/",
          },
        ],
    },
    {
        technology: [ "AngularJs", "TypeScript", "Tailwind", "API" ],
        slug: "projects/axiosmovid",
        title: "AxiosMovid",
        description:
          "This project was developed using AngularJS seamlessly integrated with the VidSRC API and TMDB API, showcasing the harmonious fusion of modern front-end technology with robust video streaming capabilities.",
        image: "https://iili.io/dVBFCV1.png",
        img: [
          {
            url: "https://iili.io/dVBFCV1.png",
          },
        ],
        link: [
          {
            text: "Live Site",
            url: "https://axios-movid.vercel.app/",
          },
        ],
    },
    {
        technology: [ "Vite","ReactJS", "TypeScript", "PostgreSQL", "Git", ],
        slug: "projects/Socio-Economic-Database-with-Geographic-Information-System",
        title: "Sedgis",
        description:
          "The Socio-Economic Database with Geographic Information System (GIS) is a comprehensive tool that combines socio-economic data with spatial information. It enables users to analyze and visualize data related to various economic factors, demographics, and geographical features. This powerful system aids in understanding the relationships between socio-economic indicators and geographic locations, facilitating informed decision-making and effective planning in various fields such as urban development, resource management, and policy formulation.",
        image: "https://iili.io/dVBK9zN.png",
        img: [
          {
            url: "https://iili.io/dVBK9zN.png",
          },
          {
            url: "https://imgur.com/YI1ifPg.png",
          },
          {
            url: "https://iili.io/dVBCSt4.jpg",
          },
        ],
        link: [
          {
            text: "No link because its confidential",
          },
          {
            text: "Trailer",
            url: "https://drive.google.com/file/d/1CXGuX-lbzbjpdRBVZiJDJCmzjGoOY0YR/view",
          },
        ],
      },
    
  ],
  
  designs: [
    {
      technology: ["Figma"],
      slug: "design/OSG-Desktop",
      title: "OSG Desktop",
      image: "https://iili.io/dVBq10F.jpg",
      link: [
        {
          text: "View in Figma",
          url: "https://rb.gy/bw6jo",
        }
      ],
    },
    {
      technology: ["Figma"],
      slug: "design/OSG-Mobile",
      title: "OSG Mobile",
      image: "https://iili.io/dVBBPyJ.jpg",
      link: [
        {
          text: "View in Figma",
          url: "https://rb.gy/bw6jo",
        },
        {
          text: "Demo Video",
          url: "https://www.youtube.com/shorts/KufRLvR5CBA",
        },
      ],
    },
    {
      technology: ["Figma"],
      slug: "design/Elegante",
      title: "Elegante",
      image: "https://iili.io/dVBCSt4.jpg",
      link: [
        {
          text: "View in Figma",
          url: "https://rb.gy/7vh77",
        },
        {
            text: "Demo Video",
            url: "https://youtu.be/6DAkD3fzxpc",
        },
      ],
    },
  ],
  More: {
    helmet: {
      title: "John Mamanao - More",
    },
    title: "Personal Interest",
    q1: "Who am I and who do I want to become?",
    q2: "Which skills do I possess?",
    q3: "Whom do I want to surround myself with?",
    q4: "What goals do I want to reach?",
    q5: "Which companies do I want to work for?",
    q6: "What type of job do I want to do?",
    q1a: "I have a diverse range of skills in technology and digital media and my passion for game development, web development, and 3D art demonstrates my creativity and technical ability, and to reach my goals, it's crucial to continue learning and taking on challenges, network with others in my field, and pursue my aspirations with determination.",
    q2a: "I have a variety of technical and digital skills and my enthusiasm for game design, web development, and 3D artwork showcases my imagination and technical proficiency.",
    q3a: "I want to surround myself with people who are passionate and knowledgeable in their field and can share their knowledge and inspire me to continue to improve. Building a network of supportive, like-minded individuals can help me achieve my goals and provide me with support and encouragement along the way.",
    q4a: "My goals is to enhance my skills in web development and game development and explore other related areas.",
    q5a: "Seeking employment at tech firms prioritizing innovation, creativity, and social responsibility, inspired by companies like Google, Apple, and Amazon.",
    q6a: "Aspiring to work as a web developer, specializing in creating visually appealing and user-friendly websites utilizing the latest web development trends and technologies.",
  },
  Profession: {
    helmet: {
      title: "John Mamanao - IT Profession",
    },
    title: "IT Profession",
    q1: "What is an IT profession for you?",
    q2: "Why do you think that your course is one of the most high-demand in the society?",
    q3: "Why do you choose BSIT course?",
    q4: "What will you become after finishing your course?",
    q5: "Describe a good IT professional.",
    q1a: "An IT profession is a career that involves working with technology systems and managing information within organizations. IT professionals are responsible for designing, implementing, and maintaining computer networks, software applications, and databases. They play a crucial role in ensuring the security, availability, and efficiency of IT infrastructure. IT professions encompass various roles such as software development, network administration, cybersecurity, and data analysis. IT professionals often need a combination of technical expertise, problem-solving skills, and effective communication to meet the technological needs of businesses and drive innovation.",
    q2a: "I choose the BSIT course due to my interest in technology, the abundance of job opportunities in the field, the constantly evolving nature of the industry, the development of problem-solving and analytical skills, the flexibility and versatility of IT skills across different sectors, and the personal growth and learning associated with pursuing my passion for technology. These reasons collectively drive me to opt for a Bachelor of Science in Information Technology (BSIT) course, enabling me to explore, excel, and contribute to the dynamic world of technology and its applications in various domains.",
    q3a: "Many students choose BSIT because it offers a diverse range of opportunities in the field of technology. It equips them with knowledge and skills in computer programming, software development, database management, networking, and cybersecurity, among other areas. The course provides a solid foundation for a career in IT, which is a rapidly evolving and in-demand field. Additionally, the potential for competitive salaries, job security, and the chance to work on innovative projects often make BSIT an attractive choice for individuals passionate about technology.",
    q4a: "Upon completing my course, I envision becoming the best version of myself in the field of information technology. As technology continues to evolve at a rapid pace, I recognize the importance of staying updated and adaptable. Therefore, I plan to continue expanding my knowledge and skills by actively learning new programming languages and staying abreast of emerging technologies. By embracing a lifelong learning mindset, I aim to remain at the forefront of industry advancements and trends, enabling me to contribute effectively to the development of innovative solutions and the successful implementation of technology-driven initiatives. Through continuous self-improvement, I aspire to make a meaningful impact in the IT industry and play a role in shaping the future of technology.",
    q5a: "A good IT professional is characterized by their technical proficiency in areas like software development, systems administration, networking, and cybersecurity, complemented by strong problem-solving skills and adaptability. They possess attention to detail, continuously learn and stay updated with evolving technologies, and communicate effectively with both technical and non-technical stakeholders. Exhibiting a proactive approach, they anticipate and address potential issues, ensuring system performance, security, and ethical conduct. Collaboration and teamwork are also key attributes, as they excel in working with others, sharing knowledge, and achieving common goals. With professionalism and a commitment to excellence, they contribute to the success of IT projects and strive for continuous growth in their field.",
  },
  Experience: {
    helmet: {
      title: "John Mamanao - OJT Experience",
    },
    title: "OJT Experience",
    intro: `"Embracing Challenges and Learning Experiences as a Student-Trainee"`,
    address: "Kilometer 7 SPED Area, Bangkal, Davao City, 8000 Davao del Sur",
    company: "NEDA Region 11",
    company_description: `NEDA Region 11, also known as NEDA Davao Region, covers the Davao Region in the southern
    part of the Philippines. The region consists of five provinces: Davao del Norte, Davao del Sur, Davao
    Oriental, Davao Occidental, and Compostela Valley. The regional office of NEDA is located in Davao City,
    the largest city in Mindanao and the regional center of Davao Region.`,
    mission_title: "Mission Statement of NEDA Region 11 (Davao Region):",
    mission_description: `"To facilitate sustainable and inclusive development in the Davao Region through effective economic
    planning, coordination, and policy formulation, ensuring the well-being and prosperity of its people."`,
    vision_title: "Vision Statement of NEDA Region 11 (Davao Region)",
    vision_description: `"A dynamic and resilient Davao Region, characterized by balanced economic growth, social equity, and
    environmental sustainability, creating opportunities for all and improving the quality of life for its
    people."`,
    essay: [
      ` Embarking on the journey of becoming a student-trainee at NEDA REGION XI, located at Kilometer 7 SPED Area, Bangkal, Davao City, 8000 Davao del Sur, has brought with it a unique set of challenges and experiences. Throughout my time in the program, starting from February 6 up to the present day, I have encountered numerous tasks that have been both demanding and rewarding. As a student-trainee at NEDA REGION XI, the most common tasks I have performed involve learning and utilizing a new programming language. Despite the difficulties that arose from being unfamiliar with the language, these tasks have been instrumental in shaping my learning experience and personal growth. This essay will delve into the specific tasks I have undertaken, the challenges I faced, and the lessons I learned during my time as a student-trainee at NEDA REGION XI.`,
      `The primary tasks that I encountered as a student-trainee at NEDA REGION XI revolved around learning a new programming language. This language, which was unfamiliar to me at the time, posed a significant challenge. Acquiring the necessary knowledge and skills to become proficient in the language required a great deal of dedication and hard work. The following tasks were completed during this period:`,
      `Attending online lecture: To familiarize myself with the new programming language, I attended a 
      series of online lectures conducted by experienced professionals. These sessions provided me 
      with foundational knowledge and practical applications of the language.`,
      `   Attending online lectures: To familiarize myself with the new programming language, I attended a series of online lectures conducted by experienced professionals at NEDA REGION XI. These sessions provided me with foundational knowledge and practical applications of the language.`,
      `   Completing programming assignments: As part of the training program at NEDA REGION XI, I was given numerous programming assignments that required the application of the newly-acquired language skills. These assignments ranged from simple exercises to more complex projects, each designed to reinforce my understanding and proficiency in the language.`,
      `   Collaborating with peers: Working alongside other student-trainees at NEDA REGION XI provided me with opportunities to collaborate, share knowledge, and learn from one another. This collaborative environment fostered a supportive atmosphere that allowed us to tackle more challenging tasks together.`,
      `   Throughout my training, I faced various challenges, including a steep learning curve, time 
      constraints, and the pressure to perform at a high level. These challenges, however, served as a 
      catalyst for personal and professional growth.`,
      `   The challenges and tasks that I faced as a student-trainee at NEDA REGION XI brought forth numerous learning experiences that have been crucial in shaping my development. The following are some of the key lessons I gleaned from my time in the program:`,
      `   Embracing challenges: The unfamiliarity of the new programming language initially seemed daunting. However, I soon realized that embracing challenges and persevering through them was an essential part of the learning process. This mindset, fostered by the supportive environment at NEDA REGION XI, allowed me to approach difficult tasks with determination and a willingness to learn.`,
      `   Developing problem-solving skills: The programming assignments and projects provided by NEDA REGION XI gave me ample opportunities to develop my problem-solving skills. By working through complex problems, I learned to break them down into smaller, more manageable tasks and to apply critical thinking and creativity in finding solutions.`,
      `   Building resilience: The demanding nature of the training program at NEDA REGION XI, coupled with the pressure to perform well, taught me the importance of resilience. I learned to bounce back from setbacks, adapt to changing circumstances, and maintain a positive attitude in the face of adversity.`,
      `   Fostering teamwork and collaboration: Collaborating with fellow student-trainees at NEDA REGION XI, I learned the value of teamwork and effective communication. By pooling our knowledge and resources, we were able to tackle more complex tasks and achieve greater results than if we had worked individually.`,
      `   Seeking guidance and support: My experiences as a student-trainee at NEDA REGION XI reinforced the importance of seeking guidance and support from mentors, instructors, and peers. This support network not only helped me overcome challenges but also enriched my learning journey.`,
      `   In conclusion, my time as a student-trainee at NEDA REGION XI has been filled with challenges, growth, and valuable experiences. The tasks I undertook, the challenges I faced, and the lessons I learned have shaped me into a more capable and resilient individual. I am grateful for the opportunity to be a part of the program at NEDA REGION XI and for the knowledge and skills I have gained along the way.`
    ],
  },
  Competency: {
    image: "https://iili.io/dVBupp9.png",
    note: `We strictly prohibit the sharing of sensitive information or system access credentials with individuals who are not
    authorized. It is imperative to maintain the highest level of confidentiality to prevent any unauthorized access or misuse of
    sensitive data. Please be aware that the system is currently under development, and as such, it is crucial to adhere to these
    security protocols to ensure the integrity and security of the system.
    `,
    about: `A database with a GIS Geographic Information System (GIS) interface is a system that integrates
    the traditional database functionalities with geospatial information. It allows for the storage,
    manipulation, and analysis of data in a geographic context, which is essential in various fields of work,
    such as urban planning, resource management, and emergency response. GIS technology has been
    revolutionizing the way people approach problem-solving in everyone’s respective fields, and the
    integration of a GIS interface with a database has taken this to the next level.
    The GIS interface enables users to visualize, analyze, and interact with geospatial data, making it
    an effective tool for decision-making. For example, in urban planning database with a GIS interface can
    be used to create maps that depict the distribution of population, housing, transportation, and other
    critical elements of a city. These maps can then be analyzed to identify areas that need improvement
    and to plan for future developments.
    In resource management, a database with a GIS interface can be used to track and manage the
    distribution of natural resources, such as water, forests, minerals, and oil. The GIS interface allows users
    to visualize the data in a spatial context, making it easier to identify trends and patterns in resource
    usage. This information can then be used to plan for sustainable resource management, to identify areas
    where conservation measures are needed, and to plan for resource extraction and distribution. In
    emergency response, a database with a GIS interface can be used to track and manage the distribution
    of resources, such as personnel, equipment, and supplies.`,
  },
  Qualities: {
    helmet: {
      title: "John Mamanao - Personal Qualities",
    },
    title: "MY PERSONALITY QUALITIES DEVELOPED DURING MY OJT EXPERIENCE",
    address: "Kilometer 7 SPED Area, Bangkal, Davao City, 8000 Davao del Sur",
    responsible: `Taking responsibility entails assuming accountability for one's actions and commitments. Throughout my educational endeavors, I have exemplified this attribute by conscientiously fulfilling my obligations and duties. During my on-the-job training, I was assigned various tasks and projects, and I made a concerted effort to accomplish them within the stipulated time frames. By assuming ownership of my work, I not only contributed to the collective triumph of the team but also earned the confidence of my superiors.`,
    confidence: `Self-assurance plays a vital role in one's personal and professional development. Throughout my on-the-job training, I cultivated self-confidence by actively engaging in conversations, offering my insights, and voicing my viewpoints. By venturing beyond my comfort zone, I acquired faith in my capabilities and bolstered my confidence in making sound decisions.`,
    social: `Effective communication and collaboration with others necessitate appropriate social skills. Throughout my on-the-job training, I engaged with a diverse range of individuals, including coworkers, superiors, and clients. I prioritized active listening, adhered to professional etiquette, and adjusted my communication approach according to varying circumstances. These encounters allowed me to enhance my social skills and recognize the significance of conveying information clearly and respectfully.`,
    honest: `Being honest is a fundamental characteristic that nurtures trust and upholds integrity. Throughout my on-the-job training, I placed a high priority on honesty by maintaining transparency regarding my progress, actively seeking assistance when required, and taking responsibility for my mistakes. Through embracing honesty, I not only cultivated credibility but also forged robust working connections built on trust and mutual respect.`,
    integrity: `Integrity encompasses abiding by ethical principles and upholding moral values. Throughout my on-the-job training, I consistently exemplified integrity by consistently displaying ethical behavior, valuing confidentiality, and making decisions that aligned with the organization's core values. Through practicing integrity, I actively fostered a constructive work environment and garnered the admiration of my peers.`,
    adapt: `Being adaptable and flexible is crucial when working in dynamic environments. Throughout my on-the-job training, I faced multiple challenges and experienced shifts in tasks and priorities. I swiftly acquired the ability to adapt with ease, maintain a flexible mindset, and willingly take on new responsibilities. By actively demonstrating my adaptability and flexibility, I not only improved my problem-solving skills but also showcased my aptitude for handling a wide range of situations.`,
    team: `Being a valuable contributor to a team entails effectively collaborating with others to achieve a shared objective. During my on-the-job training, I wholeheartedly engaged in team projects, willingly shared responsibilities, and provided support to my colleagues. I acknowledged the significance of teamwork in attaining collective triumph and forged meaningful connections with my teammates.`,
    punctual: `Exhibiting punctuality and efficiency significantly enhance professionalism and productivity. Throughout my on-the-job training, I placed great importance on punctuality by consistently arriving on time and meeting deadlines. Moreover, I dedicated myself to being efficient in task completion, maintaining a well-organized workflow, and effectively managing my time. By valuing and embodying punctuality and efficiency, I not only exemplified professionalism but also made valuable contributions to fostering a productive work atmosphere.`,
    self: `Being self-directed involves displaying initiative and proactiveness in one's professional endeavors. Throughout my on-the-job training, I assumed responsibility for my own learning journey and actively pursued chances to broaden my knowledge and enhance my skills. I actively sought feedback from my supervisors, identified areas in need of improvement, and independently worked towards optimizing my performance. By embodying self-direction, I demonstrated a proactive approach to my work and consistently strived for personal growth and excellence.`,
    positive: `A constructive work demeanor nurtures a favorable and harmonious work environment. During my on-the-job training, I consistently upheld a positive outlook, embraced challenges as chances for personal development, and tackled tasks with genuine enthusiasm. Through exuding a positive work attitude, I not only motivated myself but also inspired those around me, thus playing a significant role in fostering a positive and fruitful atmosphere.`,
    groomed: `Maintaining a professional appearance and demeanor is an essential aspect of being well-groomed. Throughout my on-the-job training, I prioritized personal grooming, dressing appropriately, and ensuring a professional demeanor. By attending to my grooming habits, I projected a positive and polished image.`,
    cooperative: `Being collaborative entails effectively collaborating with others and being receptive to cooperation. Throughout my on-the-job training, I actively embraced cooperation by actively engaging in team projects, providing assistance to my colleagues, and appreciating their contributions. I acknowledged the significance of efficient teamwork in attaining shared objectives and delivering high-quality outcomes. Through practicing cooperation, I fostered a constructive and fruitful work environment while forging robust professional bonds with my teammates.`,
    motivated: `Being driven by intrinsic motivation involves possessing a strong desire and initiative to excel in one's work. During my on-the-job training, I consistently exhibited self-motivation by assuming responsibility for my own learning, actively seeking opportunities to enhance my skills, and consistently putting in extra effort to complete tasks. I consistently sought avenues for improvement and actively sought feedback from my supervisors. By embodying self-motivation, I consistently pushed myself to surpass expectations and maximize the benefits of my OJT experience.`,
    time: `Effective task organization and optimal productivity rely on adept time management skills. Throughout my on-the-job training, I made it a priority to master time management by establishing clear objectives, devising schedules, and adhering to deadlines. I acquired the ability to prioritize tasks based on their significance and urgency, enabling me to efficiently complete them. By exemplifying proficient time management, I ensured the prompt delivery of projects while maintaining a harmonious equilibrium between various responsibilities.

    I firmly believe that these qualities were honed through my OJT experience as I actively applied them in my daily activities. The value of cooperation, self-motivation, and time management became evident in my pursuit of success within the workplace. By embodying these qualities, I effectively contributed to the team, took the initiative in my personal growth, and consistently delivered high-quality work within designated timeframes.
    
    Additionally, the positive feedback received from both supervisors and colleagues affirmed my cooperative nature, self-motivation, and adept time management skills. Their recognition further reinforced my belief in the development of these qualities during my OJT experience. The lessons learned and experiences gained throughout my OJT have significantly shaped me into a more cooperative, self-driven, and organized individual. These traits continue to be applied and refined in both personal and professional contexts.`,
  },
  Thinking: {
    title: `My Thinking Skills`,
    q1: `Problem Solving`,
    q2: `Critical Thinking`,
    q3: `Technical Knowledge`,
    q4: `Attention to Detail`,
    q5: `Analytical Thinking`,
    q6: `Continuous Learning`,
    q1a: `Problem-solving is an essential skill in IT, as I frequently encounter technical issues and challenges. I excel at recognizing problems, generating innovative solutions, and implementing efficient strategies to resolve them effectively.`,
    q2a: `As an IT professional, I rely on critical thinking to assess information, detect patterns, and establish logical connections. I possess the ability to analyze intricate problems, evaluate various solutions, and make well-founded decisions supported by evidence and logical reasoning.`,
    q3a: `In my role as an IT professional, I understand the importance of having a strong technical foundation. I have developed a comprehensive understanding of programming languages, networking, database management, cybersecurity, and system administration. This extensive knowledge serves as the bedrock for effectively utilizing my critical thinking and problem-solving skills in the field.`,
    q4a: `Being meticulous and detail-oriented is paramount in the field of IT, and I prioritize this aspect in my work. Whether I'm scrutinizing code, debugging programs, or configuring systems, I consistently exhibit a keen attention to detail to guarantee precision, accuracy, and minimal errors.`,
    q5a: `As an IT professional, I possess strong analytical thinking skills, enabling me to deconstruct intricate problems or situations into smaller elements, examine connections, and extract valuable insights. I excel at analyzing data, identifying patterns, and drawing well-founded conclusions that inform decision-making and enhance system performance.`,
    q6a: `As someone immersed in the IT field, I embrace the concept of continuous learning, recognizing that the industry is in a constant state of evolution. With new technologies and advancements regularly emerging, I maintain a mindset of ongoing growth and actively seek opportunities to acquire new skills and knowledge. I prioritize staying updated with the latest industry trends, engaging in professional development activities, and seizing chances to broaden my expertise.`,
  },
  extra: [
    {
      slug: "it-profession",
      title: "IT Profession",
      image: "https://iili.io/dVBucsj.png",
    },
    {
      slug: "ojt-experience",
      title: "OJT Experience",
      image: "https://iili.io/dVBTvR9.png",
    },
    {
      slug: "my-competency-skills",
      title: " My Competency Skills",
      image: "https://iili.io/dVBI8Cv.png",
    },
    {
      slug: "my-personal-qualities",
      title: "My Personality Qualities Developed during my OJT Experience",
      image: "https://iili.io/dVBxmxf.png",
    },
    {
      slug: "thinking-skills",
      title: "Thinking Skills",
      image: "https://iili.io/dVBoDGV.png",
    },
  ],
};
export default portfolio;
