import { Bio } from "../../components/ResumeComponents/Bio";
import { Education } from "../../components/ResumeComponents/Education";
import { Experience } from "../../components/ResumeComponents/Experience";
import { Info } from "../../components/ResumeComponents/Info";
import Layout from "../../components/layout";
import { Projects } from "../../components/ResumeComponents/Projects";
import { Skills } from "../../components/ResumeComponents/Skills";
import { Familiar } from "../../components/ResumeComponents/Familiar";
import { TechStacks } from "../../components/ResumeComponents/TechStacks";
import { Tools } from "../../components/ResumeComponents/Tools";
import './style.css';

export default function Resume({ data }) {
  return (
    <Layout>
      <div
        className={
          "max-w-screen-xl m-auto min-h-full min-h-screen min-w-full min-w-screen p-5 overflow-auto no-scrollbar bg-white text-black rounded-lg"
          
        }
      >
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 sm:grid-cols-1">
          <div>
            <img className={"w-full md:w-6/12 ml-0 mod"} src="/cvimage.jpg" alt="CV" loading="lazy"/>
            <Info data={data.info} />
            <Bio data={data.bio} />
            <Skills data={data.skills} />
            <TechStacks data={data.tech_stacks} />
          </div>
          <div>
            <h1 className="text-3xl mb-10 text-bg text-center border-2 border-solid border-black">{data.title}</h1>
            <Education data={data.education} />
            <Experience data={data.experience} />
            <Tools data={data.tools} />
            <Familiar data={data.familiar} />
          </div>
        </div>
        <Projects data={data.projects} />
      </div>
    </Layout>
  );
};
