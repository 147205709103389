import { motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { useEffect } from "react";

export default function More({ data }) {
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.4 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.helmet.title}</title>
      </Helmet>
      <motion.div
        animate={controls}
        custom={4}
        // layoutId={"about"}
        style={{ opacity: 0 }}
        className={
          "w-full h-full  model overflow-auto  z-10 flex flex-col justify-center items-center p-2"
        }
      >
        <section className="w-full h-full flex flex-col items-center">
          <div className="mt-20" />
          <div className="max-w-6xl	p-10">
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-5xl	mb-5 font-bold mb-20">{data.title}</h1>
            </div>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q1}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q1a}
            </p>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q2}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q2a}
            </p>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q3}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q3a}
            </p>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q4}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q4a}
            </p>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q5}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q5a}
            </p>
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-3xl	mb-5 font-bold">{data.q6}</h1>
            </div>
            <p className="text-xl mb-10 font-thin border border-white text-justify p-5">
              {data.q6a}
            </p>
          </div>
        </section>
      </motion.div>
    </Layout>
  );
}
