import { motion, useAnimation } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import M from "../../components/avatar";
import { useEffect } from "react";
import "./style.css";
import { ReactComponent as Projects } from '../../img/projects.svg';
import { ReactComponent as Design } from '../../img/design.svg';
import { ReactComponent as More } from '../../img/more.svg';
import { ReactComponent as Hire } from '../../img/hire.svg';

export default function Home({ data }) {
  const controls = useAnimation();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/more");
  };
  
  const hireBtn = () => {
    navigate("/contact-me");
  };

  const projectsBtn = () => {
    navigate("/projects");
  };

  const designsBtn = () => {
    navigate("/designs");
  };

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.5 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.helmet.title}</title>
      </Helmet>
      <section
        className={
          "w-full h-full relative model no-scrollbar p-5 flex items-center"
        }
      >
        <div
          className="w-full h-full flex justify-center items-center flex-col relative"
          style={{ zIndex: 10 }}
        >
          <div>
            <div
              className="splashLogo flex flex-col items-start items-center"
              style={{ zIndex: 10 }}
            >
              <div className="introduction">
                <motion.div
                  style={{ opacity: 0, x: -100 }}
                  custom={3}
                  animate={controls}
                  className="text-center"
                >
                  <div className="glitch-wrapper">
                    <span className="glitch" data-glitch="Hi, I'm John Mamanao">
                      Hi, I'm John Mamanao
                    </span>
                  </div>
                </motion.div>
                <motion.div
                  style={{ opacity: 0, x: 100, textAlign: "center" }}
                  custom={5}
                  animate={controls}
                >
                  <div
                    className="z-10 text-center glitch-v2"
                    data-glitch="a Web Developer"
                  >
                    {data.title}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
          <div className="btn-actions">
            <motion.div
              custom={7}
              animate={controls}
              style={{ opacity: 0, x: -50, flex: 1 }}
              className="border-solid border-2 border-white-500 hover:border-[#243c5a] cursor-pointer flex items-center justify-center rounded-lg flex-col"
              id="projectsbutton"
              onClick={projectsBtn}
            >
              <Projects className="pb-2" style={{ width: "25px" }}/>
              {data.btn.projects}
            </motion.div>
            <motion.div
              custom={8}
              animate={controls}
              style={{ opacity: 0, x: 50, flex: 1 }}
              className="border-solid border-2 border-white-500 hover:border-[#243c5a] cursor-pointer flex items-center justify-center rounded-lg flex-col"
              id="designsbutton"
              onClick={designsBtn}
            >
              <Design className="pb-2" style={{ width: "25px" }}/>
              {data.btn.designs}
            </motion.div>
            <motion.div
              custom={9}
              animate={controls}
              style={{ opacity: 0, y: -50, flex: 1 }}
              className="border-solid border-2 border-white-500 hover:border-[#243c5a] cursor-pointer flex items-center justify-center rounded-lg flex-col"
              id="morebutton"
              onClick={handleButtonClick}
            >
              <More className="pb-2" style={{ width: "25px" }}/>
              {data.btn.label}
            </motion.div>
            <motion.div
              custom={10}
              animate={controls}
              style={{ opacity: 0, y: 50, flex: 1 }}
              className="border-solid border-2 border-white-500 hover:border-[#243c5a] cursor-pointer flex items-center justify-center rounded-lg flex-col whitespace-no-wrap"
              id="hirebutton"
              onClick={hireBtn}
            >
              <Hire className="pb-2" style={{ width: "25px" }}/>
              <span id="hirebuttonlabel">Hire Me</span>
            </motion.div>
          </div>
        </div>
        {!data.showModel && (
          <motion.div
            id="tt"
            style={{
              opacity: 0,
              position: "absolute",
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              zIndex: 1,
            }}
            custom={8}
            animate={controls}
            className="absolute h-full w-full"
          >
            <M />
          </motion.div>
        )}
      </section>
    </Layout>
  );
}
