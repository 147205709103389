import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../components/layout";

export default function Contact({ data }) {
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.5 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.helmet.title}</title>
      </Helmet>
      <section className={"w-full h-full relative model p-5"}>
        <div
          className="w-full h-full flex justify-center items-center flex-col relative"
          style={{ zIndex: 10 }}
        >
          <div className="mt-10" />
          <div
            className="splashLogo flex flex-col items-start  p-30"
            style={{ zIndex: 10 }}
          >
            <ul>
              <motion.div
                style={{ x: -100, opacity: 0 }}
                animate={controls}
                custom={2}
                className={" text-6xl text-center"}
              >
                {data.title}
              </motion.div>
              <motion.div
                custom={3}
                animate={controls}
                style={{
                  maxWidth: 800,
                  width: "100%",
                  textAlign: "center",
                  opacity: 0,
                  y: 100,
                }}
                className="text-xl font-thin p-5"
              >
                {data.description}
              </motion.div>
              <motion.div
                custom={4}
                animate={controls}
                style={{ opacity: 0 }}
                className="text-xl font-thin text-center pb-5"
              >
                {data.ending}
              </motion.div>
            </ul>
          </div>

          <div className="flex flex-wrap justify-center items-center gap-5">
            <motion.div
              custom={5}
              animate={controls}
              style={{ opacity: 0 }}
              className="p-4 border-solid border-2 border-white hover:border-[#243c5a] cursor-pointer rounded-full"
              whileHover={{ backgroundColor: "#fff", color: "#000" }}
            >
              <a href="https://mail.google.com/mail/?view=cm&fs=1&to=beastnectus@gmail.com">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#4caf50"
                    d="M45,16.2l-5,2.75l-5,4.75L35,40h7c1.657,0,3-1.343,3-3V16.2z"
                  ></path>
                  <path
                    fill="#1e88e5"
                    d="M3,16.2l3.614,1.71L13,23.7V40H6c-1.657,0-3-1.343-3-3V16.2z"
                  ></path>
                  <polygon
                    fill="#e53935"
                    points="35,11.2 24,19.45 13,11.2 12,17 13,23.7 24,31.95 35,23.7 36,17"
                  ></polygon>
                  <path
                    fill="#c62828"
                    d="M3,12.298V16.2l10,7.5V11.2L9.876,8.859C9.132,8.301,8.228,8,7.298,8h0C4.924,8,3,9.924,3,12.298z"
                  ></path>
                  <path
                    fill="#fbc02d"
                    d="M45,12.298V16.2l-10,7.5V11.2l3.124-2.341C38.868,8.301,39.772,8,40.702,8h0 C43.076,8,45,9.924,45,12.298z"
                  ></path>
                </svg>
              </a>
            </motion.div>
            <motion.div
              custom={5}
              animate={controls}
              style={{ opacity: 0 }}
              className="p-4 border-solid border-2 border-white hover:border-[#243c5a] cursor-pointer rounded-full"
              whileHover={{ backgroundColor: "#fff", color: "#000" }}
            >
              <a href="https://github.com/BeastNectus">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#fff"
                    d="M41,24c0,9.4-7.6,17-17,17S7,33.4,7,24S14.6,7,24,7S41,14.6,41,24z"
                  ></path>
                  <path
                    fill="#455a64"
                    d="M21 41v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-6.5c0-.3.2-.5.5-.5s.5.2.5.5V41h2v-5.5c0-.3.2-.5.5-.5s.5.2.5.5V41h1.8c.2-.3.2-.6.2-1.1V36c0-2.2-1.9-5.2-4.3-5.2h-2.5c-2.3 0-4.3 3.1-4.3 5.2v3.9c0 .4.1.8.2 1.1L21 41 21 41zM40.1 26.4C40.1 26.4 40.1 26.4 40.1 26.4c0 0-1.3-.4-2.4-.4 0 0-.1 0-.1 0-1.1 0-2.9.3-2.9.3-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2-.3 3.1-.3 1.1 0 2.4.4 2.5.4.1 0 .1.1.1.2C40.2 26.3 40.2 26.4 40.1 26.4zM39.8 27.2C39.8 27.2 39.8 27.2 39.8 27.2c0 0-1.4-.4-2.6-.4-.9 0-3 .2-3.1.2-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.1.1 0 2.2-.2 3.1-.2 1.3 0 2.6.4 2.6.4.1 0 .1.1.1.2C39.9 27.1 39.9 27.2 39.8 27.2zM7.8 26.4c-.1 0-.1 0-.1-.1 0-.1 0-.1.1-.2.8-.2 2.4-.5 3.3-.5.8 0 3.5.2 3.6.2.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0-2.7-.2-3.5-.2C10.1 26 8.6 26.2 7.8 26.4 7.8 26.4 7.8 26.4 7.8 26.4zM8.2 27.9c0 0-.1 0-.1-.1 0-.1 0-.1 0-.2.1 0 1.4-.8 2.9-1 1.3-.2 4 .1 4.2.1.1 0 .1.1.1.1 0 .1-.1.1-.1.1 0 0 0 0 0 0 0 0-2.8-.3-4.1-.1C9.6 27.1 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9 8.2 27.9z"
                  ></path>
                  <path
                    fill="#455a64"
                    d="M14.2,23.5c0-4.4,4.6-8.5,10.3-8.5c5.7,0,10.3,4,10.3,8.5S31.5,31,24.5,31S14.2,27.9,14.2,23.5z"
                  ></path>
                  <path
                    fill="#455a64"
                    d="M28.6 16.3c0 0 1.7-2.3 4.8-2.3 1.2 1.2.4 4.8 0 5.8L28.6 16.3zM20.4 16.3c0 0-1.7-2.3-4.8-2.3-1.2 1.2-.4 4.8 0 5.8L20.4 16.3zM20.1 35.9c0 0-2.3 0-2.8 0-1.2 0-2.3-.5-2.8-1.5-.6-1.1-1.1-2.3-2.6-3.3-.3-.2-.1-.4.4-.4.5.1 1.4.2 2.1 1.1.7.9 1.5 2 2.8 2 1.3 0 2.7 0 3.5-.9L20.1 35.9z"
                  ></path>
                  <path
                    fill="#00bcd4"
                    d="M24,4C13,4,4,13,4,24s9,20,20,20s20-9,20-20S35,4,24,4z M24,40c-8.8,0-16-7.2-16-16S15.2,8,24,8 s16,7.2,16,16S32.8,40,24,40z"
                  ></path>
                </svg>
              </a>
            </motion.div>
            <motion.div
              custom={5}
              animate={controls}
              style={{ opacity: 0 }}
              className="p-4 border-solid border-2 border-white hover:border-[#243c5a] cursor-pointer rounded-full"
              whileHover={{ backgroundColor: "#fff", color: "#000" }}
            >
              <a href="https://www.linkedin.com/in/john-mamanao-762218278">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </a>
            </motion.div>
            <motion.div
              custom={5}
              animate={controls}
              style={{ opacity: 0 }}
              className="p-4 border-solid border-2 border-white hover:border-[#243c5a] cursor-pointer rounded-full"
              whileHover={{ backgroundColor: "#fff", color: "#000" }}
            >
              <a href="https://www.facebook.com/Venectus">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="30"
                  height="30"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#039be5"
                    d="M24 5A19 19 0 1 0 24 43A19 19 0 1 0 24 5Z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M26.572,29.036h4.917l0.772-4.995h-5.69v-2.73c0-2.075,0.678-3.915,2.619-3.915h3.119v-4.359c-0.548-0.074-1.707-0.236-3.897-0.236c-4.573,0-7.254,2.415-7.254,7.917v3.323h-4.701v4.995h4.701v13.729C22.089,42.905,23.032,43,24,43c0.875,0,1.729-0.08,2.572-0.194V29.036z"
                  ></path>
                </svg>
              </a>
            </motion.div>
          </div>
        </div>
      </section>
    </Layout>
  );
}
