import { motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import { useEffect } from "react";
import "../ojt-experience/style.css";

export default function Thinking({ data }) {
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.4 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>John Mamanao - Thinking Skills</title>
      </Helmet>
      <motion.div
        animate={controls}
        custom={4}
        // layoutId={"about"}
        style={{ opacity: 0 }}
        className={
          "max-w-screen-xl m-auto min-h-full w-full h-full z-10"
        }
      >
        <section className="w-full h-full flex flex-col items-center">
          <div className="mt-20" />
          <div className="max-w-6xl">
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-5xl font-bold mb-20">{data.title}</h1>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.q1}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q1a}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   {data.q2}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q2a}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   {data.q3}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q3a}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.q4}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q4a}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.q5}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q5a}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  {data.q6}
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.q6a}
              </p>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  );
}
