import { motion, useAnimation } from "framer-motion";
import { useEffect} from "react";

import A from "../../components/bg";
import { Link } from "react-router-dom";
import Static from "../../portfolio_static";
import theme from "../../theme";

export default function Layout(props) {
  const { layout } = Static;
  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: 0.3 },
    }));
  }, [controls]);

  const p = layout.left.reduce((acc, curr) => {
    acc[curr.url] = (
      <Link className="vertical-rotate " to={curr.url}>
        {curr.label}
      </Link>
    );
    return acc;
  }, {});

  const a = [...layout?.right, ""].map((e, i) =>
    layout?.right.length === i ? (
      <img
        src={"/favicon-32x32.png"}
        style={{
          width: 35,
          padding: 5,
        }}
        alt=""
      />
      
    ) : e?.link ? (
      <Link className="vertical-rotate" to={e.url}>
        {e.label}
      </Link>
    ) : (
      <a className="vertical-rotate" href={e.url}>
        {e.label}
      </a>
    )
  );

  return (
    <main
      className="relative h-auto w-auto overflow-hidden"
      style={{
        zIndex: 10,
        background: theme.backgroundColor,
        color: theme.textColor,
      }}
    >
      {/* Background Effect */}
      {!props.disableExtra && (
        <motion.div
          animate={controls}
          style={{ opacity: 0, zIndex: -1 }}
          custom="5"
          className="fixed h-full w-full pointer-events-none"
        >
          <A />
        </motion.div>
      )}
      <div className="flex w-screen h-screen">
        {!props.disableExtra && (
          <div className="flex flex-col justify-between border-r-4">
              {Object.entries(p).map((e, i) => (
                <motion.div
                  key={i}
                  layoutId={e[0]}
                  animate={controls}
                  style={{ opacity: 0, zIndex: 99 }}
                  custom={i}
                  className="p-4"
                  whileHover={{ backgroundColor: '#fff', color: '#000' }}
                >
                  {e[1]}
                </motion.div>
              ))}
          </div>
        )}
        <div className="w-full p-5 overflow-auto no-scrollbar flex items-center">{props.children}</div>
        {!props.disableExtra && (
          <div className="flex flex-col justify-between border-l-4">
            {a.map((e, i) => (
              <motion.div
                key={i}
                animate={controls}
                style={{ opacity: 0, zIndex: 99 }}
                custom={i}
                className="p-4"
                whileHover={{ backgroundColor: '#fff', color: '#000' }}
              >
                {e}
              </motion.div>
            ))}
          </div>
        )}
      </div>
    </main>
  );
}
