
import { AnimatePresence, motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import Layout from "../../components/layout";
import Helmet from "react-helmet";
import './style.css';

export default function Design({ data }) {
  const controls = useAnimation();

  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.2 },
    }));
  }, [controls]);

  


  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>John Mamanao - {data.title}</title>
      </Helmet>
      <div className="h-full w-full flex overflow-auto gallery-div">
        <div className="h-full w-full flex flex-col justify-center p-2">
          <motion.div
            custom={1}
            animate={controls}
            className="text-4xl font-extrabold"
            style={{ opacity: 0, x: -10 }}
          >
            {data.title}
          </motion.div>
          <br />
          <motion.div
            custom={2}
            animate={controls}
            style={{ opacity: 0, x: -10 }}
            className="text-xl font-thin"
          >
            <b>Technology</b> : {data.technology.map((e) => ` ${e},`)}
          </motion.div>
          <motion.div
            custom={3}
            animate={controls}
            style={{ opacity: 0, y: -10 }}
            className="flex gap-5 mt-10"
          >
            {data.link.map((e, i) => (
              <a
                key={i}
                href={e.url}
                target="_blank"
                className="text-xl p-2 border-solid border-2 border-white-500 hover:border-[#243c5a] hover:bg-white hover:text-black cursor-pointer"
              >
                {e.text}
              </a>
            ))}
          </motion.div>
        </div>
        <AnimatePresence>
          <motion.div
            layoutId={data.slug}
            className="h-full w-full flex justify-center items-center p-2"
          >
            <img className="w-full h-full object-contain" src={data.image} alt="" loading="lazy"/>
          </motion.div>
        </AnimatePresence>
      </div>
    </Layout>
  );
}
