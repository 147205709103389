export const Media = [
    {type: 'image', url: require('./img/1.png')},
    {type: 'image', url: require('./img/2.png')},
    {type: 'image', url: require('./img/3.png')},
    {type: 'image', url: require('./img/4.png')},
    {type: 'image', url: require('./img/5.png')},
    {type: 'image', url: require('./img/6.png')},
    {type: 'image', url: require('./img/7.png')},
    {type: 'image', url: require('./img/8.png')},
    {type: 'image', url: require('./img/9.png')},
    {type: 'image', url: require('./img/10.png')},
    {type: 'image', url: require('./img/11.png')},
    {type: 'image', url: require('./img/12.png')},
    {type: 'image', url: require('./img/13.png')},
    {type: 'image', url: require('./img/14.png')},
    {type: 'image', url: require('./img/15.png')},
    {type: 'image', url: require('./img/16.png')},
    {type: 'image', url: require('./img/17.png')},
    {type: 'image', url: require('./img/18.png')},
    {type: 'image', url: require('./img/19.png')},
    {type: 'image', url: require('./img/20.png')},
    {type: 'image', url: require('./img/21.png')},
    {type: 'image', url: require('./img/22.png')},
    {type: 'image', url: require('./img/23.png')},
    {type: 'image', url: require('./img/24.png')},
];