import { motion, useAnimation } from "framer-motion";

import { Helmet } from "react-helmet";
import Layout from "../../../components/layout";
import { useEffect } from "react";
import "../ojt-experience/style.css";

export default function Qualities({ data }) {
  const controls = useAnimation();
  useEffect(() => {
    controls.start((i) => ({
      opacity: 1,
      x: 0,
      z: 4,
      y: 0,
      transition: { delay: i * 0.4 },
    }));
  }, [controls]);

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data.helmet.title}</title>
      </Helmet>
      <motion.div
        animate={controls}
        custom={4}
        // layoutId={"about"}
        style={{ opacity: 0 }}
        className={
          "max-w-screen-xl m-auto min-h-full w-full h-full z-10"
        }
      >
        <section className="w-full h-full flex flex-col items-center">
          <div className="mt-20" />
          <div className="max-w-6xl">
            <div className="md:flex justify-between md:pb-50">
              <h1 className="text-5xl font-bold mb-20">{data.title}</h1>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  Being Responsible
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.responsible}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   Having self-confidence
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.confidence}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   Possessing appropriate social skills
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.social}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  Being Honest
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.honest}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  Having Integrity
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.integrity}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  Demonstrating adaptability and flexibility
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.adapt}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   Being a team player
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.team}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                  Being punctual and efficient
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.punctual}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                   Being self-directed
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.self}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                    Possessing a positive work attitude
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.positive}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                    Being well groomed
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.groomed}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                     Being cooperative
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.cooperative}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                     Being self-motivated
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.motivated}
              </p>
            </div>
            <div className="mb-20">
              <div className="md:flex justify-between md:pb-50">
                <h1 className="text-3xl mb-5 font-bold italic">
                     Demonstrating good time management
                </h1>
              </div>
              <p className="text-xl mb-10 font-thin">
                {data.time}
              </p>
            </div>
          </div>
        </section>
      </motion.div>
    </Layout>
  );
}
