import React, { useEffect } from "react";
import { motion, useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";

export default function Designs({ data, helmetData }) {
    const design = data;
    const controls = useAnimation();

    useEffect(() => {
        controls.start((i) => ({
            opacity: 1,
            x: 0,
            z: 4,
            y: 0,
            scale: 1,
            transition: { delay: i * 0.8 },
        }));
    }, [controls]);

    return (
        <Layout>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{helmetData.designs}</title>
            </Helmet>
            <div className={"max-w-screen-xl m-auto min-h-full min-h-screen min-w-full min-w-screen p-5 overflow-auto w-full h-full"}>
                <div className="mt-10" />
                <motion.div
                    custom={0}
                    style={{ opacity: 0, y: 10, scale: 0 }}
                    animate={controls}
                    className="text-6xl font-bold"
                >
                    UI/UX DESIGN.
                </motion.div>
                <br />

                <div className="grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
                    {design.map((e, i) => (
                        <Link
                            to={"/" + e.slug}
                            key={e.slug}
                        >
                            <motion.div
                                custom={i + 1}
                                layoutId={e.slug}
                                whileHover={{ scale: 1.01 }}
                                style={{ opacity: 0, y: 100 }}
                                animate={controls}
                                className="border-solid border-2 border-white flex justify-center p-2"
                            >
                                <img
                                    className="w-full h-auto object-contain"
                                    src={e.image}
                                    alt=""
                                    loading="lazy"
                                />
                            </motion.div>
                        </Link>
                    ))}
                </div>
            </div>
        </Layout>
    );
}
