export function Info({ data }) {
  return (
    <div className={"flex mt-10"}>
      <div
        style={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          transform: "rotate(180deg)",
        }}
        className={"pt-10 text-xl flex justify-center"}
      >
        <p className={"tracking-widest text-bg border-2 border-solid border-black"}>{data.title}</p>
      </div>
      <div className={"px-10 text-base"}>
        <span className={"tracking-widest text-md font-bold"}>
          {data.name}
          <span className={"text-black-300 font-bold"}>{data.age}</span>
        </span>
        {data.list.map((e) => (
          <p>
            <span className="text-black-300 font-normal">{e[0]}</span>
            <span className={"text-gray-300text-md font-light"}>{e[1]}</span>
          </p>
        ))}
      </div>
    </div>
  );
}
