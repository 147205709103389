import "./global.css";
import { HashRouter, Route, Routes } from "react-router-dom";
import React from "react";
import About from "./page/about";
import Contact from "./page/contact";
import { Helmet } from "react-helmet";
import Home from "./page/home";
import NotFound from "./page/404";
import Project from "./page/project";
import Projects from "./page/projects";
import ReactDOM from "react-dom";
import Resume from "./page/resume";
import Static from "./portfolio_static";
import More from "./page/more";
import Extras from "./page/extras";
import Profession from "./page/extras/it-profession";
import Experience from "./page/extras/ojt-experience";
import Competency from "./page/extras/competency-skills";
import Qualities from "./page/extras/personality-qualities";
import Thinking from "./page/extras/thinking-skills";
import Designs from "./page/designs";
import Design from "./page/design";

const App = () => {
  return (
    <>
        <Helmet>
          <meta
            name="description"
            content="Just a developer with dreams of becoming as famous as Elon Musk."
          />
        </Helmet>
        <div>
          <HashRouter>
            <Routes>
              <Route path="/" element={<Home data={Static.home} />} />
              <Route path="/about-me" element={<About data={Static.About} />} />
              <Route path="/resume" element={<Resume data={Static.resume} />} />
              <Route
                path="/contact-me"
                element={<Contact data={Static.contact} />}
              />
              <Route
                path="/email"
                element={<Contact data={Static.contact} />}
              />
              <Route
                path="/projects"
                element={<Projects data={Static.projects} helmetData={Static.Helmet}/>}
              />
              <Route path="/more" element={<More data={Static.More} />} />
              <Route
                path="/it-profession"
                element={<Profession data={Static.Profession} />}
              />
              <Route
                path="/ojt-experience"
                element={<Experience data={Static.Experience} />}
              />
              <Route
                path="/my-competency-skills"
                element={<Competency data={Static.Competency} />}
              />
              <Route
                path="/my-personal-qualities"
                element={<Qualities data={Static.Qualities} />}
              />
              <Route
                path="/thinking-skills"
                element={<Thinking data={Static.Thinking} />}
              />
              <Route
                path="/extra-information"
                element={<Extras data={Static.extra} helmetData={Static.Helmet}/>}
              />
              <Route
                path="/designs"
                element={<Designs data={Static.designs} helmetData={Static.Helmet}/>}
              />
              {Static.designs.map((e, index) => (
                <Route key={index} path={e.slug} element={<Design data={e} />} />
              ))}
              {Static.projects.map((e, index) => (
                <Route key={index} path={e.slug} element={<Project data={e} />} />
              ))}
              <Route path="*" element={<NotFound />} />
            </Routes>
          </HashRouter>
        </div>
    </>
  );
};
ReactDOM.render(<App />, document.getElementById("root"));
