export function Education({ data }) {
  return (
    <div className={"flex "}>
      <div
        style={{
          writingMode: "vertical-rl",
          textOrientation: "mixed",
          transform: "rotate(180deg)",
        }}
        className={"pt-10 text-xl flex justify-center"}
      >
        <span className={"tracking-widest text-bg border-2 border-solid border-black"}>{data.title}</span>
      </div>
      <div className={"px-10 text-base"}>
        {data.list.map((e) => (
          <div className={"mb-10 text-lg"}>
            <p>
              <span>{e.range}</span>
              <span className={"text-black-300 font-normal"}>{e.company}</span>
            </p>
            <p className={"text-gray-300text-md font-light"}>{e.course}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
